<template>
  <div class="container ml-10">
    <p class="text-2xl mb-12">Account Information</p>
    <a-row type="flex">
      <img
        :src="designer.profileImg ? designer.profileImg : '/img/avatar.png'"
        alt=""
        :style="{ borderRadius: '10px', border: '3px solid rgb(218 218 218)' }"
        class="w-40 h-auto"
      />
    </a-row>
    <a-row class="mt-8">
      <a-col :xs="24" :md="12" :lg="6">
        <p class="text-xl my-3">
          Full Name:
          <span class="text-md text-gray-500">{{ designer.name }}</span>
        </p>
      </a-col>
      <a-col :xs="24" :md="12" :lg="6">
        <p class="text-xl my-3">
          Brand Name:
          <span class="text-md text-gray-500">{{ designer.brandName }}</span>
        </p>
      </a-col>
    </a-row>
    <a-row>
      <a-col :xs="24" :md="12" :lg="6">
        <p class="text-xl my-3">
          Email:
          <span class="text-md text-gray-500">{{ designer.email }}</span>
        </p>
      </a-col>
      <a-col :xs="24" :md="12" :lg="6">
        <p class="text-xl my-3">
          Phone Number:
          <span class="text-md text-gray-500">{{ designer.phone }}</span>
        </p>
      </a-col>
    </a-row>
    <a-row :gutter="20" class="mt-12">
      <a-col>
        <a-card class="my-3">
          <p class="text-xl text-left">Total Collections</p>
          <p class="text-red-800 font-bold text-xl text-center">
            {{ designer.collectionsTotal }}
          </p>
        </a-card>
      </a-col>
      <a-col>
        <a-card class="my-3">
          <p class="text-xl text-left">Total Looks</p>
          <p class="text-red-800 font-bold text-xl text-center">
            {{ designer.totalLooks || 'N/A' }}
          </p>
        </a-card>
      </a-col>
    </a-row>
    <div class="mt-20">
      <p class="text-2xl mb-8">Brand Description:</p>
      <a-row>
        <a-col :xs="24" :md="16" :lg="12">
          <p>
            {{ designer.description || 'N/A' }}
          </p>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Overview',
  props: {
    designer: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>

<style scoped></style>
